import React, { useEffect } from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

  

const Llibres = ({ data, location }) => {
    var artists=data.allMysqlHamabeadsEn.edges
    console.log(artists);
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title={`Tots els Llibres`} />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">Tots els Llibres</h1>
        <section itemProp="articleBody">
        <div class="row">
        {artists.map(node => (
            <div className="hamaitem col-md-4 col-sm-4 col-xs-12" key={node.artist.id}>
                <div className="hamaitembg">
                  <Link to={`/${node.artist.slug}/`}>
                        <div class="imgcaja">
                            <img className="hamabeads2" title={`${node.artist.nombre}`} src={`${node.artist.image}`} alt={`${node.artist.nombre}`} />
                        </div>
                        <div class="textocaja">
                            <h3>{node.artist.nombre}</h3>
                        </div>
                  </Link>
                </div>
            </div>
        ))}
        </div>
        </section>
    </article>
    </Layout>
  )
}

export default Llibres

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMysqlHamabeadsEn (sort: { fields: [nombre], order: ASC }) {
      edges {
        artist: node {
          id
          nombre
          image
          slug
        }
      }
    }
  }
`
